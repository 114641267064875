import { ItemList } from "schema-dts";

import createListItem, { ListItemData } from "./create-list-item";

export default function createItemList(data: ListItemData[]): ItemList {
    return {
        '@type': 'ItemList',
        itemListElement: data.map(
            (item, index) => createListItem({ position: index + 1, ...item }))
    }
}