import React from "react";
import { useLocation } from "@reach/router";

import { categoryElement, categoryLink } from "./category-item.module.scss";

import { IPostCategory } from "../../../models/post.model";

import Link from "../link";

export interface ICategoryItem {
    category: IPostCategory;
}

export default function CategoryItem({ category }: ICategoryItem) {
    const { pathname } = useLocation();
    const slug = `/blog/${category.slug}`;

    return (
        <li className={categoryElement}>
            <Link
                key={category.categoryId}
                to={slug}
                type="filled"
                color={pathname.includes(slug) ? "secondary" : "grey"}
                smallPaddingHeight
                textUppercase={false}
                className={categoryLink}
                //@fixme: setting padding in scss didn't work so I needed to do it here.
                //@fixme: If you have better solution - do it.
                style={{ padding: "1rem 0" }}
            >
                {category.name}
            </Link>
        </li>
    );
}
