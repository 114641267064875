import { ItemList } from "schema-dts";

import { IPostStructuredData } from "../../models/structured-data.model";
import createImage from "./create-image";
import { ISite } from "../../models/site.model";
import createAuthor from "./create-author";
import createItemList from "./create-item-list";

export default function createBlogListing(posts: IPostStructuredData[], siteMetadata: ISite['siteMetadata']): ItemList {
    const postItems = posts.map(post => ({
        name: post.name,
        url: `${siteMetadata.url}/blog/${post.slug}`,
        datePublished: post.datePublished,
        dateModified: post.dateModified,
        image: createImage({
            siteUrl: siteMetadata.url,
            imageUrl: post.image ? post.image : '',
        }),
        author: createAuthor(post.author, siteMetadata),
    }));

    return createItemList(postItems);
}